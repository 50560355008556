

<template>
  <v-app>
    <v-main>
      <v-app-bar style="position: fixed" class="text-no-wrap" :elevation="3" rounded scroll-behavior="hide">
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-app-bar-title>Call Agent Helper</v-app-bar-title>
        <v-spacer></v-spacer>

        <template v-slot:append>
          <div class="NavBarGroup" v-if="account">
            <v-btn @click="swapComponent(5)">Home</v-btn>
            <v-btn @click="swapComponent(1)">Change Login Email</v-btn>
            <v-btn @click="swapComponent(2)">Change Membership #</v-btn>
            <v-btn @click="swapComponent(71)">Update Virtual Membership Card</v-btn>
            <v-btn v-if="!account" @click="swapComponent(70)">Sign In</v-btn>
            <v-btn v-if="account" @click="swapComponent(69)">Sign Out</v-btn>
            <!-- <v-btn @click="swapComponent(3)">Check for login errors</v-btn> -->
          </div>
        </template>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" temporary v-if="account">
        <v-list :items="this.items" @click="swapComponent($event)">
        </v-list>
      </v-navigation-drawer>

      <div id="toReplace" center block v-if="account">
        <component v-bind:is="currentComponent"></component>
        <div v-show="!currentComponent" v-bind="component2 in this.componentsArray">
        </div>
      </div>
      <div class="signOutDiv mb-3 mt-3" v-else justify="center">
        <v-col style="margin: 20px">
          <v-row>You must be signed in to view this application. Please sign in using a valid @seatow.com email
            address.
          </v-row>
          <v-row div class="mt-6">
            <v-btn @click="SignIn">Sign In</v-btn>
          </v-row>
        </v-col>

      </div>
    </v-main>
  </v-app>
</template>

<script>
import { shallowRef } from "vue";
import ChangeAuth0AssociatedMembership from "./components/ChangeAuth0AssociatedMembership.vue";
import ChangeAuth0Email from "./components/ChangeAuth0Email.vue";
import HomeComponent from "./components/HomeComponent.vue";
import UpdateVirtualMembershipCard from "./components/UpdateVirtualMembershipCard.vue";

import { PublicClientApplication } from '@azure/msal-browser';
import { mapMutations } from 'vuex';

export default {
  created() {
    document.title = "CC Auth0 Helper App";
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
  },
  name: "App",
  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    this.$emitter.emit('login', this.account);
  },
  data: () => ({
    account: undefined,
    containers: [],
    items: [
      {
        title: "Home",
        value: 0,
      },
      {
        title: "Change Login Email",
        value: 1,
      },
      {
        title: "Change Membership #",
        value: 2,
      },
      {
        title: "Sign Out",
        value: 69
      },
      {
      title: "Update Virtual Membership Card",
      value: 71
      }
    ],
    drawer: false,
    group: null,
    currentComponent: shallowRef(HomeComponent),
    componentsArray: [
      shallowRef(HomeComponent),
      shallowRef(ChangeAuth0Email),
      shallowRef(ChangeAuth0AssociatedMembership),
      shallowRef(UpdateVirtualMembershipCard)
    ],
  }),
  methods: {
    ...mapMutations(['setAccessToken']),
    async SignIn() {
      await this.$msalInstance
        .loginPopup({})
        .then(() => {
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];
          this.$emitter.emit('login', this.account);
        })
        .catch(error => {
          console.error(`error during authentication: ${error}`);
        });
    },
    async SignOut() {
      await this.$msalInstance
        .logoutPopup({})
        .then(() => {
          this.$emitter.emit('logout', 'logging out');
          //clear cookie
          this.setAccessToken(null);
          this.account = undefined;
        })
        .catch(error => {
          console.error(error);
        });
    },
    swapComponent: function (refCom) {
      if (typeof (refCom) == "number") {
        if (refCom == 1) {
          this.currentComponent = shallowRef(ChangeAuth0Email);
        }
        else if (refCom == 2) {
          this.currentComponent = shallowRef(ChangeAuth0AssociatedMembership);
        }
        else if(refCom == 71){
          this.currentComponent = shallowRef(UpdateVirtualMembershipCard);
        }
        else if (refCom == 69) {
          this.SignOut();
        }
        else if (refCom == 70) {
          this.SignIn();
        }
        else {
          this.currentComponent = shallowRef(HomeComponent);
        }

        return;
      }

      if (refCom.target.innerText == "Change Login Email") {
        this.currentComponent = shallowRef(ChangeAuth0Email);
      } else if (refCom.target.innerText == "Change Membership #") {
        this.currentComponent = shallowRef(ChangeAuth0AssociatedMembership);
      }else if(refCom.target.innerText == "Update Virtual Membership Card"){
        this.currentComponent = shallowRef(UpdateVirtualMembershipCard);
      }
       else if (refCom.target.innerText == "Sign Out") {
        this.SignOut();
      } else if (refCom.target.innerText == "Sign In") {
        this.SignIn();
      } else {
        this.currentComponent = shallowRef(HomeComponent);
      }

    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style>
.changeauth0associatedmembership .chanegauth0email .homecomponent .toReplace .updateVirtualMembershipCard {
  max-width: 539px;
}

pre {
  outline: 1px solid #ccc;
}

.signOutDiv {
  display: flex !important;
  height: 100vh !important;
  margin: 0px !important;
}

@media only screen and (max-width: 900px) {
  .NavBarGroup {
    display: none;
  }
}
</style>
