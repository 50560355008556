import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: '7f040c58-2601-46aa-a38b-2a9de94ad809',
        //  dev -> '6850d125-d709-40b9-8e88-cd0b19983b07'
          authority:
            'https://login.microsoftonline.com/ff5a36c3-1fe4-4f3f-8662-de463e12109d',
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      },
      accessToken: ''
    };
  },
  mutations :{
    setAccessToken(state, token){
      state.accessToken = token;
    }
  }
});

export default store;