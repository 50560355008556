<template>
  <div class="home pa-md-4 mx-lg-auto" align="center">
    <h1 class="mt-5 mb-10">Call Agent Helper Overview</h1>
    <v-expansion-panels
      fill-height
      fluid
      style="max-width: 50%"
      v-model="panel"
      :disabled="disabled"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-title>What is this site?</v-expansion-panel-title>
        <v-expansion-panel-text>
          This site is a helper tool designed to make managing website users
          easier.
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          >What does "Change Login Email" do?</v-expansion-panel-title
        >
        <v-expansion-panel-text>
          <v-col>
            <v-row class="test">
              Change login email allows you to change the email address
              associated with a user's website login.
            </v-row>
            <v-row class="test example">
              <b>Example</b>
            </v-row>
            <v-row class="test"
              >John Brown calls the customer support number stating that he
              wants to change this email from john.brown@gmail.com to
              john.brown@aol.com. Use this component to achieve this.</v-row
            >
          </v-col>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          >What does "Change Member #" do?</v-expansion-panel-title
        >
        <v-expansion-panel-text>
          <v-col>
            <v-row class="test">
              Change Member # allows you to change the membership number associated to a website login.
            </v-row>
            <v-row class="test example">
              <b>Example</b>
            </v-row>
            <v-row class="test"
              >Beyoncé calls the customer support number stating that she
              tried to associate her online account to her membership, but she typed in her membership number wrong. She meant to type in 412344 but she actually typed 412345. This means that the website is now pulling up the wrong membership when she signs in. To fix this, use the change membership # component. </v-row
            >
          </v-col>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          >What does Update Virtual Membership Card Do?</v-expansion-panel-title
        >
        <v-expansion-panel-text>
          <v-col>
            <v-row class="test">
              Update Virtual Membership Card updates a google and apple Virtual Card.
            </v-row>
            <v-row class="test example">
              <b>Example</b>
            </v-row>
            <v-row class="test"
              >Darth Vader calls customer service, explaining that he renewed his membership in 2023 and received a new virtual card. However, the card still displays the 2023 expiration date instead of 2024, indicating a discrepancy. Use the Update Virtual Membership Card component to fix this.</v-row
            >
          </v-col>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- <v-expansion-panel>
        <v-expansion-panel-title
          >What does "Check For Login Errors" do?</v-expansion-panel-title
        >
        <v-expansion-panel-text>
          <v-col>
            <v-row class="test">
              Check For Login Errors allows you to easily diagnose a "white screen" problem that a member is having.
            </v-row>
            <v-row class="test example">
              <b>Example</b>
            </v-row>
            <v-row class="test"
              >Derek Jeter is calling in complaining that when he signs in, all he sees is a white page. Go to the "Check for login errors" component and type in the users membership number. The component will automatically try and diagnose + fix the problem that the customer is having. If successful, the member will be able to refresh the page and see their membership dashboard. If failure, please reach out to pgillan@seatow.com and send over appropriate details.</v-row
            >
          </v-col>
        </v-expansion-panel-text>
      </v-expansion-panel> -->
    </v-expansion-panels>
  </div>
</template>

<style scoped>

.test {
  margin: 0 0 0 0;
}
.example {
  margin: 19px 0 3px 0;
}
.v-row {
  text-align: left;
}


</style>

<script>
export default {
  data: () => ({
    panel: [0, 1, 2, 3],
    disabled: false,
  }),
};
</script>