<template>
  <div class="updateVirtualMembershipCard">
    <v-form v-model="valid" @submit="checkForm">
      <v-container align="center" style=" max-width:650px">
        <h1 v-if="debug">debug mode is on: {{ debug }}</h1>
        <h1 class="mt-5">Update Virtual Membership Card</h1>
        <p></p>
        <h4 class="mb-3">
          Utilize this component to correct inaccuracies in Virtual Membership Card values and ensure they reflect the
          accurate information.
        </h4>

        <v-row style="max-width:550px">
          <v-col cols="12" md="80">
            <v-text-field v-model="membershipNumber" label="Membership Number" :rules="membershipNumberRules" required
              @input="clearState();" dense></v-text-field>
          </v-col>
        </v-row>

        <div v-if="!isAccount" class="padding">
          <v-row justify="center" class="py-6">
            <v-btn type="submit" elevation="1" outlined raised>Verify Members Account Name
            </v-btn>
          </v-row>
        </div>

        <!-- Any errors display for CCC Rep to fix before sending request -->
        <v-row justify="center" class="padding">
          <v-alert v-if="validationErrors.length" type="error" variant="outlined"
            title="Please correct the following error(s):" class="text-start alert-size" icon="mdi-alert-circle">
            <p v-for="error in validationErrors" :key="error">{{ error }}</p>
          </v-alert>
        </v-row>

      </v-container>

      <!-- Dialog to confirm correct account and continue processing the VCard membership -->
      <v-row justify="center">
        <div v-if="dialog">
          <template>
            <div class="text-center py-4 width">
              <v-dialog v-model="dialog" max-width="500" persistent justify="center">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn v-bind="activatorProps">
                    Open Dialog
                  </v-btn>
                </template>
                <v-card prepend-icon="mdi-alert-box-outline" class="flex-center">
                  <template v-slot:prepend>
                    <div class="pe-4">
                      <v-icon color="yellow" size="x-large"></v-icon>
                    </div>
                    <v-card-title class="text-center font-weight-medium text-grey-darken-2" justify="center">
                      Process Virtual Card Update?
                    </v-card-title>
                  </template>
                  <v-card-text class="text-center">
                    <div class="text-center block">
                      <p class="py-4 text-h6 font-weight-medium text-grey-darken-2">Membership Number: </p>
                      <p class="text-h6 font-weight-regular">{{ accountResp['membershipNumber'] }}</p>
                    </div>
                    <div class="text-center block">
                      <p class="py-4 text-h6 font-weight-medium text-grey-darken-2">Account Name: </p>
                      <p class="text-h6 font-weight-regular">{{ accountResp['accountName'] }}</p>
                    </div>
                  </v-card-text>
                  <template v-slot:actions>
                    <v-spacer></v-spacer>
                    <v-card-actions justify="center">
                      <v-btn class="mx-8" color="primary" justify="center" variant="tonal" size="large"
                        @click="dialog = false; loaderDialog = true; updateVirtualCard()">
                        Yes
                        <v-icon icon="mdi-checkbox-marked-circle" end></v-icon>
                      </v-btn>
                      <v-btn class="mx-8" color="red" @click="dialog = false" justify="center" variant="tonal"
                        size="large">
                        No
                        <v-icon icon="mdi-cancel" end></v-icon>
                      </v-btn>
                    </v-card-actions>
                  </template>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </div>
        <div v-if="accountStatus && accountResp && accountStatus !== 200" class="py-6">
          <v-alert title="Status" text="Invalid Membership Number, try to re-enter the Membership Number" type="error"
            variant="outlined" icon="mdi-alert-circle" class="text-start alert-size"></v-alert>
        </div>
        <div v-else-if="accountStatus && accountStatus !== 200">
          <v-alert title="Status" text="Bad Request - Try Again" type="error"
            variant="outlined" icon="mdi-alert-circle" class="text-start alert-size"></v-alert>
        </div>

        <!-- Loader Dialog -->
        <div v-if="loaderDialog">
          <template>
            <div class="pa-4 text-center">
              <v-dialog v-model="loaderDialog" max-width="320" persistent>
                <v-list class="py-2" color="primary" elevation="12" rounded="lg">
                  <v-list-item prepend-icon="mdi-ferry" title="Updating Membership...">
                    <template v-slot:prepend>
                      <div class="pe-4">
                        <v-icon color="yellow" size="x-large"></v-icon>
                      </div>
                    </template>

                    <template v-slot:append>
                      <v-progress-circular color="primary" indeterminate="disable-shrink" size="16"
                        width="2"></v-progress-circular>
                    </template>
                  </v-list-item>
                </v-list>
              </v-dialog>
            </div>
          </template>
        </div>
        <!-- end loader dialog -->
      </v-row>
    </v-form>
    <v-row justify="center" class="mt-8">
      <div v-if="virtualCardResp != null">
        <div v-if="debug == true">
          <pre>{{ virtualCardResp }}</pre>
        </div>
        <div v-if="virtualCardStatus && virtualCardResp && virtualCardStatus === 200 && loaderDialog === false">
          <div class="status-container">
            <v-alert title="Status" :text="alertMessage" :type="type" variant="outlined" icon="mdi-check-outline"
              class="text-start alert-size"></v-alert>
            <div class="py-4"></div>
            <v-alert v-if="virtualCardResp['status_code'] === 201" title="Take Action"
              text="Send the Customer A Digital Membership Card Email in Salesforce" type="info" variant="outlined"
              icon="mdi-email" class="text-start alert-size"></v-alert>
          </div>
        </div>
        <div v-else-if="virtualCardStatus && virtualCardStatus !== 200">
          <v-alert title="Status" text="Bad Request - Try Again" type="error" variant="outlined" icon="mdi-alert-circle"
            class="text-start alert-size"></v-alert>
        </div>
      </div>
    </v-row>
  </div>
</template>


<script>
export default {
  data() {
    return {
      membershipNumberRules: [
        v => !!v || 'Membership number is required',
        v => /^\d+$/.test(v) || 'Membership number can only contain numbers',
        v => (v && v.length <= 9) || 'Membership number must be less than or equal to 9 digits'
      ],
      loaderDialog: false,
      dialog: false,
      isAccount: false,
      validationErrors: [],
      membershipNumber: null,
      accountStatus: null,
      accountResp: null,
      virtualCardResp: null,
      virtualCardStatus: null,
      valid: null,
      type: null,
      alertMessage: null,
      virtualCardEndpoint: 'updateVirtualCard',
      accountNameEndpoint: 'accountNameFromMembership'
    }
  },
  name: "UpdateVirtualMembershipCard",
  props: {
    msg: String,
    description: String,
    debug: Boolean
  },
  methods: {
    //validation check
    checkForm: function (e) {
      this.validationErrors = [];

      if (!this.membershipNumber) {
        this.validationErrors.push("Membership Number Required")
      }
      else if (!this.validMembershipNumber(this.membershipNumber)) {
        this.validationErrors.push("Valid Membership Number Required")
      }

      //stop reroute from browser
      e.preventDefault()

      //send request to api
      if (this.validationErrors.length <= 0) this.getAccountName();
      else return;
    },
    // To validate before submission - different then just displaying warning rules
    validMembershipNumber: function (membershipNumber) {
      const rules = [
        v => !!v || 'Membership number is required',
        v => /^\d+$/.test(v) || 'Membership number can only contain numbers',
        v => (v && v.length <= 9 && v.length > 0) || 'Membership number must be less than or equal to 9 digits'
      ];

      for (let rule of rules) {
        if (rule(membershipNumber) !== true) {
          return false;
        }
      }
      return true;
    },
    updateVirtualCard() {
      this.virtualCardStatus = null
      this.virtualCardResp = null

      fetch(
        `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VER}/${this.virtualCardEndpoint}`,
        {
          method: 'POST',
          body: JSON.stringify({membershipNumber : this.membershipNumber}),
          headers: {
            "Request-Token": "WnZr4u7x!A%D*G-KaPdSgUkXp2s5v8y/",
            "Content-Type" : "application/json"
          }
        }
      )
        .then((resp) => {
          this.virtualCardStatus = resp.status;
          return resp.json()
        })
        .then((data) => {
          this.virtualCardResp = data;
        })
        .then(() => {
          this.buildCCResponse(this.virtualCardResp);
          this.setType(this.virtualCardResp);
        })
        .then(() => {
          return new Promise((resolve) => setTimeout(resolve, 1350));
        })
        .catch((e) => {
          //Handle 500
          console.log("Error when updating Cards",e)
          this.virtualCardStatus = 500
        })
        .finally(() => {
          this.membershipNumber = null;
          this.accountStatus = null;
          this.accountResp = null;
          this.loaderDialog = false;
        });
    },
    getAccountName() {
      this.accountStatus = null
      this.accountResp = null
 
      fetch(`${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VER}/${this.accountNameEndpoint}/${this.membershipNumber}`, {
        method: 'GET',
        headers: {
          "Request-Token": "WnZr4u7x!A%D*G-KaPdSgUkXp2s5v8y/",
        }
      }
      )
        .then((resp) => {
          this.accountStatus = resp.status;
          return resp.json()
        })
        .then((data) => {
          this.accountResp = data

          //success -> open dialog, build text, build label
          if (this.accountStatus === 200) {
            this.dialog = true;
          }
        })
        .catch((e) => {
          //Handle 500
          console.log("500 error fetching account name",e)
          this.accountStatus = 500
        })
    },
    clearState() {
      if (!this.valid) return
      this.validationErrors = []
      this.virtualCardResp = null;
      this.virtualCardStatus = null;
      this.showResponse = false
      this.accountStatus = null;
      this.accountResp = null;
    },
    buildCCResponse(virtualCardResp) {
      switch (virtualCardResp['status_code']) {
        case 201:
        case 400:
        case 404:
          this.alertMessage = `${virtualCardResp['data']}`;
          break;
        case 500:
          this.alertMessage = "Something Went Wrong Try Again"
          break;
        default:
          this.alertMessage = "Something Went Wrong with the request"
          break;
      }
    },
    setType(virtualCardResp) {
      switch (virtualCardResp['status_code']) {
        case 201:
          this.type = "success"
          break;
        case 400:
        case 404:
        case 500:
          this.type = "error";
          break;
        default:
          this.type = "error";
          break;
      }
    }
  }
};
</script>

<style>
.status-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.error {
  color: red;
}

.padding {
  padding: 10px;
}

.width {
  width: 500px;
  height: 250px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 10px;
}

.block {
  display: block;
}

.alert-size {
  width: 600px;
  max-width: 600px;
  height: 100px;
  max-height: 100px;
}
</style>