<template>
  <div class="chanegauth0email">
    <v-form v-model="valid">
      <v-container align="center" style=" max-width:650px">
        <h1 v-if="debug">debug mode is on: {{ debug }}</h1>
        <h1 class="mt-5">Change Auth0 Email Address</h1>
        <p></p>
        <h4 class="mb-3">
          Use this component if you wish to change a user's login email address
        </h4>

        <v-row style="max-width:550px">
          <v-col cols="12" md="80">
            <v-text-field
              v-model="oldUsername"
              :rules="emailRules"
              label="Current Username"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="80">
            <v-text-field
              class="mt-n8"
              dense
              v-model="newUsername"
              :rules="emailRules"
              label="New Username"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-btn @click="callapi()" elevation="1" outlined raised
            >Change Email
          </v-btn>
        </v-row>
      </v-container>

      <v-row justify="center" class="mt-8">
        <div v-if="resp != null">
          <div v-if="debug == true">
            <pre>{{ resp }}</pre>
          </div>
          <div v-if="status == 200">User updated successfully</div>
          <div v-else>{{ resp }}</div>
        </div>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      oldUsername: null,
      newUsername: null,
      resp: null,
      status: null,
      valid: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
        () => this.oldUsername!=this.newUsername || "Desired username is the same as the old username",
      ],
    };
  },
  name: "ChangeAuth0Email",
  props: {
    msg: String,
    description: String,
    debug: Boolean
  },
  methods: {
    callapi() {
      this.status = null;
      this.resp = null;
      var old_username = this.oldUsername;
      var new_username = this.newUsername;
      axios
        .post(
          `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VER}/auth0changeemail/${old_username}/${new_username}`,
          null,
          {
            headers: {
              "Request-Token": "WnZr4u7x!A%D*G-KaPdSgUkXp2s5v8y/",
            },
          }
        )
        .then((response) => {
          this.status = response.status;
          this.resp = response.data;
        })
        .catch((error) => {
          this.status = error.response.status;
          this.resp = error.response.data.message;
        });
    },
  },
};
</script>